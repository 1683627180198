import { useButton } from '@react-aria/button';
import { useFocusRing } from '@react-aria/focus';
import { mergeProps } from '@react-aria/utils';
import { ReactNode, useId, useRef } from 'react';
import styled from 'styled-components';
import { ShortcutsConfig, useHotkeys } from '../../../hooks/useHotkeys.tsx';
import { Box } from '../../../layout/Box/Box.tsx';
import { Column } from '../../../layout/Row/Column.tsx';
import { Row } from '../../../layout/Row/Row.tsx';
import { Stack } from '../../../layout/Stack/Stack.tsx';
import { borderWidthActiveLink } from '../../../tokens/decision/border.ts';
import { colorAccent } from '../../../tokens/decision/colors.ts';
import { IconSize } from '../../../tokens/quarks/iconSize.ts';
import { Spacing } from '../../../tokens/quarks/spacing.ts';
import { px } from '../../../tokens/utils/utils.ts';
import { Chevron } from '../../Chevron/Chevron.tsx';
import { Icons } from '../../Icons/components/icons.ts';
import { IconName } from '../../Icons/types.ts';
import { OptionalTooltip } from '../../Tooltip/OptionalTooltip.tsx';
import { RefinedNavigationItem } from './RefinedNavigationLink.tsx';

type Props = Readonly<{
  children: ReactNode;
  toggleDisabled?: boolean;
  iconName: IconName;
  isActive?: boolean;
  label: string;
  isExpanded: boolean;
  onIsExpandedChange?: (isExpanded: boolean) => void;
}>;

const StyledLabel = styled.div<{ readonly $isActive?: boolean }>`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  border-bottom:${({ $isActive }) => ($isActive ? `${borderWidthActiveLink}px solid ${colorAccent}` : `${borderWidthActiveLink}px solid transparent`)};
  margin-bottom: ${px(-borderWidthActiveLink)};
`;

export const RefinedNavigationToggle = ({
  iconName,
  toggleDisabled,
  isActive,
  label,
  isExpanded,
  onIsExpandedChange,
  children,
}: Props) => {
  const { isFocusVisible, focusProps } = useFocusRing();
  const controlledElementId = useId();
  const Icon = Icons[iconName];
  const buttonRef = useRef<HTMLDivElement>(null);
  const wrapperRef = useRef<HTMLLIElement>(null);

  const { buttonProps } = useButton(
    {
      isDisabled: toggleDisabled,
      elementType: 'div',
      onPress: () => onIsExpandedChange?.(!isExpanded),
      'aria-expanded': isExpanded,
      'aria-controls': controlledElementId,
    },
    buttonRef,
  );

  useHotkeys(
    {
      [ShortcutsConfig.Escape]: () => {
        if (isExpanded) {
          onIsExpandedChange?.(false);
          buttonRef.current?.focus();
        }
      },
    },
    {
      ref: wrapperRef,
      isDisabled: toggleDisabled,
    },
  );

  return (
    <Box component="li" marginX={Spacing.S} ref={wrapperRef}>
      <Stack spacing={Spacing.XS}>
        <OptionalTooltip
          placement="right"
          text={label}
          customRenderText={(truncatedElementRef) => (
            <RefinedNavigationItem
              $isFocusVisible={isFocusVisible}
              $isDisabled={toggleDisabled}
              {...mergeProps(focusProps, buttonProps)}
              ref={buttonRef}
            >
              <Row alignY="center" spacingX={Spacing.S} noWrap>
                <Column width="content">
                  <Icon size={IconSize.S} />
                </Column>
                <Column>
                  {label && (
                    <Box display="flex">
                      <StyledLabel $isActive={isActive && !isExpanded} ref={truncatedElementRef}>
                        {label}
                      </StyledLabel>
                    </Box>
                  )}
                </Column>
                {!toggleDisabled && (
                  <Column width="content">
                    <Chevron direction={isExpanded ? 'up' : 'down'} />
                  </Column>
                )}
              </Row>
            </RefinedNavigationItem>
          )}
        />

        {isExpanded && (
          <Box paddingLeft={Spacing.XL}>
            <Stack component="ul" id={controlledElementId} align="start" spacing={Spacing.XS}>
              {children}
            </Stack>
          </Box>
        )}
      </Stack>
    </Box>
  );
};
